import styled from "@emotion/styled"

import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"
import { Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"

import { SINGLE_SELECT_DISPLAY_TYPE, arrowIconWidth, errorIconWidth } from "../constants"

import Tags from "../../Tags"
import Body from "../../Typography/Body"
import { useSelectContext } from "@ariakit/react"

const StyledSelectValue = styled.div`
  max-width: ${({ errorMessage }) => {
    return errorMessage ? `calc(100% - ${arrowIconWidth} - ${errorIconWidth})` : `calc(100% - ${arrowIconWidth})`
  }};
`

export function SelectValue({ disabled, isMulti, placeholderText, selectedValueDisplay }) {
  const selectStore = useSelectContext()

  const selectedValue = selectStore.useState("value")
  const options = selectStore.useState("items")

  const renderCustomSelectedValueLabel = () => {
    const commonSelectedValueBodyProps = {
      as: "p",
      type: "body",
      color: disabled ? "colorTextDisabled" : "colorTextStrong",
    }

    const getSelectedOption = value => options.find(option => option.value === value)

    // Always work with an array to simplify logic
    const valuesArray = Array.isArray(selectedValue) ? selectedValue : [selectedValue]
    const labelsArray = valuesArray
      .map(value => getSelectedOption(value)?.labelText)
      .filter(label => label !== undefined)

    // Handle case where no labels are found
    if (!labelsArray.length) {
      return (
        <Body {...{ color: disabled ? "colorTextDisabled" : "colorTextWeakest", type: "headingXs" }}>
          {placeholderText || localized("Select")}
        </Body>
      )
    }

    // Single select scenario
    if (!isMulti) {
      if (selectedValueDisplay === SINGLE_SELECT_DISPLAY_TYPE.ICON) {
        const selectedOption = getSelectedOption(selectedValue)

        return <Flex alignItems="center">{selectedOption.icon}</Flex>
      }

      return <Body {...commonSelectedValueBodyProps}>{labelsArray[0]}</Body>
    }

    // Default multi-select scenario
    const parsedTagLabels = labelsArray.map((label, index) => ({
      label,
      id: `${index}-${label}-tag`,
      variant: disabled ? "disabled" : "default",
    }))

    return <Tags {...{ labels: parsedTagLabels, maxItemsDisplayed: 20, showRemainingItemsTooltip: false }} />
  }

  return <StyledSelectValue>{renderCustomSelectedValueLabel()}</StyledSelectValue>
}
