import { useEffect } from "react"
import PropTypes from "prop-types"
import { isNil } from "ramda"
import styled from "@emotion/styled"
import { Input, Select, TableFilterButton } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { localized } from "js/includes/common/utils"
import { useDebounce, useMountedState } from "js/includes/common/hooks"
import { Flex } from "js/includes/components/Styled"

const StyledRow = styled(Flex)`
  margin-top: ${sizer(1)};
  flex-direction: row;
`

const StyledInput = styled(Input)`
  height: 38px;
  min-width: auto;
  width: ${({ width = "100px" }) => width};
  margin-right: ${sizer(2)};
`

const getValidationResult = (validators = [], selectedValue, inputValue) => {
  let result = {
    success: true,
  }

  if (isNil(selectedValue)) {
    //Only run validators if selectedValue is selected
    return result
  }

  validators.some(validator => {
    const validationResult = validator(inputValue)
    if (!validationResult.success) {
      result = validationResult
    }
    return !validationResult.success
  })

  return result
}

const getSelectedLabel = (options, selectedValue) => {
  const { labelToken, labelText } = options.find(o => o.value === selectedValue)
  //TODO: Add support for LabelComponent
  return labelToken ? localized(labelToken) : labelText
}

const InputWithDropdown = ({
  inputProps: { type = "number", ...restInputProps },
  value,
  onChange,
  inputValidators,
  labelToken,
  options,
}) => {
  const [selectedValue, setSelectedValue] = useMountedState(value.selectedValue)
  const [inputValue, setInputValue] = useMountedState(value.inputValue)
  const debouncedInputValue = useDebounce(inputValue, 250)

  const { success, message: errorMessage } = getValidationResult(inputValidators, selectedValue, debouncedInputValue)

  useEffect(() => {
    if (selectedValue && success) {
      onChange({ selectedValue, inputValue: debouncedInputValue })
    }
  }, [success, selectedValue, debouncedInputValue, onChange])

  return (
    <StyledRow>
      <StyledInput
        {...{
          type,
          value: inputValue,
          onChange: e => setInputValue(e.target.value),
          errorMessage,
          ...restInputProps,
        }}
      />
      <Select
        {...{
          size: "sm",
          buttonRenderer: () => (
            <TableFilterButton
              {...{
                labelToken,
                renderAsDiv: true,
                hideLabelOnSingleSelect: true,
                handleClick: e => e.stopPropagation(),
                onRemove: () => setSelectedValue(null),
                ...(selectedValue && { filters: [{ label: getSelectedLabel(options, selectedValue) }] }),
              }}
            />
          ),
          onChange: setSelectedValue,
          options,
          value: selectedValue ?? "",
          popoverProps: {
            portal: false,
          },
        }}
      />
    </StyledRow>
  )
}

InputWithDropdown.propTypes = {
  inputProps: PropTypes.object,
  value: PropTypes.shape({
    inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedValue: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  inputValidators: PropTypes.array,
  labelToken: PropTypes.string.isRequired,
  options: PropTypes.array,
}

export default InputWithDropdown
