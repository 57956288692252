import { spacingBase } from "./spacing"

const itemColumnSpan = {
  XS: "span 2",
  S: "span 4",
  M: "span 6",
  L: "span 8",
  XL: "span 12",
}

const itemRowSpan = {
  XS: "span 1",
  S: "span 2",
  M: "span 3",
  L: "span 4",
  XL: "span 5",
  "2XL": "span 6",
  "3XL": "span 7",
}

const gapSize = spacingBase * 4
const gap = `${gapSize}px`

const itemBaseHeight = 154
const itemHeight = {
  XS: `${itemBaseHeight}px`,
  S: `${itemBaseHeight * 2 + gapSize}px`,
  M: `${itemBaseHeight * 3 + gapSize * 2}px`,
  L: `${itemBaseHeight * 4 + gapSize * 3}px`,
  XL: `${itemBaseHeight * 5 + gapSize * 4}px`,
  "2XL": `${itemBaseHeight * 6 + gapSize * 5}px`,
  "3XL": `${itemBaseHeight * 7 + gapSize * 6}px`,
}

export const grid = {
  gap,
  itemColumnSpan,
  itemRowSpan,
  itemHeight,
}
